import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { propertyService } from "../../services/propertyService";

// Async thunk for fetching properties
export const fetchProperties = createAsyncThunk(
  "properties/fetchProperties",
  async (tower = "", { rejectWithValue }) => {
    try {
      const response = await propertyService.fetchProperties(tower);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const initialState = {
  properties: [],
  activeTab: "all",
  loading: false,
  initialLoad: true,
  error: null,
};

export const propertySlice = createSlice({
  name: "properties",
  initialState,
  reducers: {
    setActiveTab: (state, action) => {
      state.activeTab = action.payload;
    },
    setInitialLoadComplete: (state) => {
      state.initialLoad = false;
    }
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchProperties.pending, (state) => {
        // Only show loading for initial load
        state.loading = state.initialLoad;
        state.error = null;
      })
      .addCase(fetchProperties.fulfilled, (state, action) => {
        state.loading = false;
        state.properties = action.payload.data.properties;
        state.initialLoad = false; // Mark initial load as complete
      })
      .addCase(fetchProperties.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || "Failed to fetch properties";
        state.initialLoad = false;
      });
  },
});

export const { setActiveTab, setInitialLoadComplete } = propertySlice.actions;
export default propertySlice.reducer;
