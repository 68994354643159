import React from 'react';

const CircularLoader = () => {
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-20 z-50">
      <div className="w-16 h-16 border-4 border-t-4 border-red-600 border-l-red-600 border-r-black border-b-black rounded-full animate-spin">
      </div>
    </div>
  );
};

export default CircularLoader;