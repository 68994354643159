import React, { useState } from "react";
import { HomeIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";
import { ChevronDownIcon } from "lucide-react";

const TowerTabs = ({ activeTab, setActiveTab, towers }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  return (
    <div className="mb-6">
      {/* Show Label - Mobile Only */}
      <div className="lg:hidden px-4">
        <div className="text-text-14 font-medium text-gray-500 mb-2">Show:</div>
        <div className="relative">
          <button
            onClick={() => setIsDropdownOpen(!isDropdownOpen)}
            className="w-full px-4 py-2 bg-white border border-gray-300 rounded-lg flex justify-between items-center"
          >
            <span className="text-text-14 font-medium">
              {activeTab === "all" ? "All Towers" : activeTab}
            </span>
            <ChevronDownIcon className="h-5 w-5" />
          </button>

          {isDropdownOpen && (
            <div className="absolute z-10 mt-1 w-full bg-white border border-gray-300 rounded-lg shadow-lg">
              <button
                onClick={() => {
                  setActiveTab("all");
                  setIsDropdownOpen(false);
                }}
                className="w-full px-4 py-2 text-left text-text-14 hover:bg-gray-100"
              >
                All Towers
              </button>
              {towers.map((tower) => (
                <button
                  key={tower}
                  onClick={() => {
                    setActiveTab(tower);
                    setIsDropdownOpen(false);
                  }}
                  className="w-full px-4 py-2 text-left text-text-14 hover:bg-gray-100"
                >
                  {tower}
                </button>
              ))}
            </div>
          )}
        </div>
      </div>

      {/* Tabs */}
      <div className="hidden lg:block border-b border-gray-200">
        <div className="overflow-x-auto scrollbar-hide">
          <nav className="-mb-px flex whitespace-nowrap" aria-label="Tabs">
            <button
              onClick={() => setActiveTab("all")}
              className={clsx(
                "group inline-flex items-center py-3 px-4 border-b-2 font-bold text-sm sm:text-base font-lato shrink-0",
                activeTab === "all"
                  ? "border-textBlue text-textBlue"
                  : "border-transparent text-gray-500 hover:text-black hover:border-textBlack"
              )}
            >
              <HomeIcon className="mr-2 h-4 w-4" />
              All Towers
            </button>

            {towers.map((tower) => (
              <button
                key={tower}
                onClick={() => setActiveTab(tower)}
                className={clsx(
                  "py-3 px-4 border-b-2 font-medium text-sm sm:text-base shrink-0",
                  activeTab === tower
                    ? "border-textBlue text-textBlue"
                    : "border-transparent text-gray-500 hover:text-black hover:border-textBlack"
                )}
              >
                {tower}
              </button>
            ))}
          </nav>
        </div>
      </div>

      <style jsx>{`
        .scrollbar-hide {
          -ms-overflow-style: none;
          scrollbar-width: none;
        }
        .scrollbar-hide::-webkit-scrollbar {
          display: none;
        }
      `}</style>
    </div>
  );
};

export default TowerTabs;
