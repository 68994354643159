import React, { useState } from "react";
import info from "../../../assets/info.png";
import building from "../../../assets/building.png";
import { ChevronDown } from "lucide-react";

const BuildingInfo = () => {
  const [isExpanded, setIsExpanded] = useState(false);

  const remarks = ["Half-month rent Agency fee"];
  const summerPromo = [
    "3 months free + Qatar cool & Kahramaa included",
    "Rental prices below are prorated already (15 months)",
    "Strictly, Pre-termination is allowed for loss of employment ONLY",
    "1 Security Deposit - (Cash or Dated Cheque)",
    "Cheques must be computerized and not hand-written with signature verification",
  ];
  const requirements = [
    "QID, Passport, Salary Certificate, Email address & Mobile no.",
  ];

  return (
    <div className="bg-white rounded-lg shadow-sm p-4 md:p-6 mb-6">
      <div className="flex flex-col lg:flex-row lg:justify-between">
        {/* Logo and Title Section */}
        <div className="hidden lg:flex lg:flex-1 items-center space-x-4">
          <div className="bg-white rounded-full flex items-center justify-center">
            <img src={building} alt="Building Icon" className="w-200 h-200" />
          </div>
          <h2 className="font-lato font-bold text-custom-62 text-textBlack">
            Listings
          </h2>
        </div>

        {/* Vertical Divider - Desktop Only */}
        <div className="hidden lg:block w-px mx-8 xl:mx-20 my-4 bg-grey self-stretch" />

        {/* Content Section */}
        <div className="lg:flex-1">
          <div className="space-y-2">
            {/* Date Info */}
            <div className="flex items-center">
              <img src={info} alt="Info Icon" className="h-5 w-5" />
              <p className="ml-2 text-text-14 font-poppins font-semibold text-darkBlack">
                As of 03 November 2024
              </p>
            </div>

            {/* Preview Content - Mobile */}
            <div className="lg:hidden space-y-2 ml-1">
              <div className="flex items-start">
                <span className="mr-2 text-textBlack">•</span>
                <span className="text-text-14 font-roboto text-textBlack">
                  Half-month rent Agency fees
                </span>
              </div>
              <div className="flex items-start">
                <span className="mr-2 text-textBlack">•</span>
                <span className="text-text-14 font-roboto text-textBlack">
                  3 months free + Qatar cool & Kahramaa..
                </span>
              </div>
            </div>

            {/* Expand Button - Mobile Only */}
            <button
              onClick={() => setIsExpanded(!isExpanded)}
              className="lg:hidden flex items-center text-textBlack font-roboto font-bold text-text-12 underline decoration-solid"
            >
              Read more information
              <ChevronDown
                className={`ml-1 w-4 h-4 transition-transform duration-200 ${
                  isExpanded ? "rotate-180" : ""
                }`}
              />
            </button>

            {/* Full Content */}
            <div className={`space-y-2 ${!isExpanded && "hidden lg:block"}`}>
              <div className="lg:ml-7">
                <h3 className="text-text-14 font-roboto font-bold text-textBlack">
                  REMARKS:
                </h3>
                <ul className="mt-1 space-y-1 ml-2">
                  {remarks.map((remark, index) => (
                    <li key={index} className="flex items-start">
                      <span className="mr-2 text-textBlack">•</span>
                      <span className="text-text-14 font-roboto text-textBlack">
                        {remark}
                      </span>
                    </li>
                  ))}
                </ul>
              </div>

              <div className="lg:ml-7">
                <h3 className="text-text-14 font-roboto font-bold text-textBlack">
                  SUMMER PROMO:
                </h3>
                <ul className="mt-1 space-y-1 ml-2">
                  {summerPromo.map((promo, index) => (
                    <li key={index} className="flex items-start">
                      <span className="mr-2 text-textBlack">•</span>
                      <span className="text-text-14 font-roboto text-textBlack">
                        {promo}
                      </span>
                    </li>
                  ))}
                </ul>
              </div>

              <div className="lg:ml-7">
                <h3 className="text-text-14 font-roboto font-bold text-textBlack">
                  REQUIREMENTS:
                </h3>
                <ul className="mt-1 space-y-1 ml-2">
                  {requirements.map((requirement, index) => (
                    <li key={index} className="flex items-start">
                      <span className="mr-2 text-textBlack">•</span>
                      <span className="text-text-14 font-roboto text-textBlack">
                        {requirement}
                      </span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BuildingInfo;
