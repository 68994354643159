import axios from "axios";

const BASE_URL = process.env.REACT_APP_PUBLIC_URL;

export const propertyService = {
  /**
   * Fetch property listings
   * @param {string} [tower=''] - Optional tower filter
   * @returns {Promise} Promise resolving to property listings
   */
  async fetchProperties(tower = "") {
    try {
      // If tower is provided and not 'all', include it in the request
      const params =
        tower && tower !== "all" ? { tower: tower.toLowerCase() } : {};

      const response = await axios.get(`${BASE_URL}/properties/listing`, {
        params,
        headers: {
          Authorization: ``,
        },
      });
      return response.data;
    } catch (error) {
      console.error("Error fetching properties:", error);
      throw error;
    }
  },
};
