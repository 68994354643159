import React from "react";
import { flexRender } from "@tanstack/react-table";
import { usePropertyTable } from "../../../hooks/usePropertyTable";
import TowerCard from "./TowerCard";
import { Button } from "@headlessui/react";

const PropertyTable = ({ data }) => {
  const table = usePropertyTable(data);

  // Handle empty data scenario
  if (!data || data.length === 0) {
    return (
      <div className="text-center py-10 text-gray-500">
        Loading...
      </div>
    );
  }

  return (
    <>
      {/* Mobile View - Tower Cards */}
      <div className="lg:hidden space-y-4">
        {data?.map((property) => (
          <TowerCard
            key={property?._id }
            tower={property.tower}
            propertyId={property.id}
            noOfBed={property.beds}
            baseRent={property.rent}
            proRatedPrice={property.prorated_price}
            view={property.location}
            propertyType={property.type}
            bua={property.size}
            status={property.status}
            contact={property.agent_contact}
          />
        ))}
      </div>

      <div className="hidden lg:block overflow-x-auto">
        <div className="w-full bg-white rounded-lg shadow-sm">
          <table className="min-w-full w-full table-auto">
            <thead className="bg-gray-50">
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <th
                      key={header.id}
                      className="px-4 py-3 text-left text-text-14 font-bold font-lato text-textBlack tracking-wider bg-lightBlue"
                      style={{
                        width: header.getSize() ? `${header.getSize()}px` : 'auto',
                        minWidth: header.getSize() ? `${header.getSize()}px` : 'auto',
                      }}
                      onClick={header.column.getToggleSortingHandler()}
                    >
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody className="bg-white">
              {table.getRowModel().rows.map((row) => (
                <tr key={row.id} className="border-b">
                  {row.getVisibleCells().map((cell) => (
                    <td
                      key={cell.id}
                      className="px-4 py-4 text-text-14 font-normal font-lato text-textBlack"
                      style={{
                        width: cell.column.getSize() ? `${cell.column.getSize()}px` : 'auto',
                        minWidth: cell.column.getSize() ? `${cell.column.getSize()}px` : 'auto',
                      }}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>

          {/* Pagination */}
          <div className="px-6 py-3 flex items-center justify-between border-t border-gray-200">
            <div className="flex gap-2">
              {/* <Button
                onClick={() => table.setPageIndex(0)}
                disabled={!table.getCanPreviousPage()}
                variant="secondary"
              >
                {"<<"}
              </Button> */}
              <Button
                onClick={() => table.previousPage()}
                disabled={!table.getCanPreviousPage()}
                variant="secondary"
              >
                Previous
              </Button>
              <Button
                onClick={() => table.nextPage()}
                disabled={!table.getCanNextPage()}
                variant="secondary"
              >
                Next
              </Button>
              {/* <Button
                onClick={() => table.setPageIndex(1)}
                disabled={!table.getCanNextPage()}
                variant="secondary"
              >
                {">>"}
              </Button> */}
            </div>
            <span className="text-sm text-gray-700">
              Page {table.getState().pagination.pageIndex + 1} of{" "}
              {table.getPageCount()}
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default PropertyTable;
