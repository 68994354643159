import {
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
} from "@tanstack/react-table";
import { useMemo } from "react";
import Badge from "../components/common/Badge";

export const usePropertyTable = (data) => {
  const columns = useMemo(
    () => [
      {
        accessorKey: "tower",
        header: "Tower",
        size: 70,
        cell: ({ getValue }) => getValue() || 'N/A',
      },
      {
        accessorKey: "id",
        header: "Property ID",
        size: 120,
        cell: ({ getValue }) => getValue() || 'N/A',
      },
      {
        accessorKey: "beds",
        header: "No. Of Bed",
        size: 120,
        cell: ({ getValue }) => getValue() || 'N/A',
      },
      {
        accessorKey: "rent",
        header: "Base Rent (QAR)",
        size: 120,
        cell: ({ getValue }) => {
          const value = getValue();
          return value ? `${value.toLocaleString()}` : 'N/A';
        },
      },
      {
        accessorKey: "prorated_price",
        header: "Pro Rated Price",
        size: 120,
        cell: ({ getValue }) => {
          const value = getValue();
          return value ? `${value.toLocaleString()}` : 'N/A';
        },
      },
      {
        accessorKey: "location",
        header: "View",
        size: 150,
        cell: ({ getValue }) => getValue() || 'N/A',
      },
      {
        accessorKey: "type",
        header: "Property Type",
        size: 120,
        cell: ({ getValue }) => getValue() || 'N/A',
      },
      {
        accessorKey: "size",
        header: "BUA (SQM)",
        size: 110,
        cell: ({ getValue }) => {
          const value = getValue();
          return value ? `${value.toLocaleString()}` : 'N/A';
        },
      },
      {
        accessorKey: "status",
        header: "Status",
        size: 110,
        cell: ({ row }) => {
          // const status = row.original.status || 'Available';
          return <Badge status={'Available'} />;
        },
      },
      {
        accessorKey: "agent_contact",
        header: "Contact",
        size: 120,
        cell: ({ getValue }) => getValue() || 'N/A',
      },
    ],
    []
  );

  const table = useReactTable({
    data: data || [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    initialState: {
      pagination: {
        pageSize: 10,
      },
    },
  });

  return table;
};