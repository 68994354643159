import React from 'react';
import clsx from 'clsx';

const Badge = ({ status }) => {
  return (
    <span
      className={clsx(
        "px-3 py-1 text-xs font-medium rounded-md",
        status === "Available" && "bg-greenBadge text-blackBadge font-bold",
        status === "Rented" && "bg-yellow-300 text-gray-800",
        status === "Pending" && "bg-yellow-100 text-yellow-800"
      )}
    >
      {status}
    </span>
  );
};

export default Badge;