import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import Header from "./components/layout/Header";
import BuildingInfo from "./components/features/properties/BuildingInfo";
import TowerTabs from "./components/features/properties/TowerTabs";
import PropertyTable from "./components/features/properties/PropertyTable";
import CircularLoader from "./components/common/CircularLoader";
import { fetchProperties, setActiveTab } from "./redux/slices/propertySlice";

const App = () => {
  const dispatch = useDispatch();
  const { properties, activeTab, error, loading, initialLoad } = useSelector(
    (state) => state.properties
  );

  const towers = useMemo(() => {
    const uniqueTowers = [...new Set(properties?.map((prop) => prop.tower))];
    return uniqueTowers;
  }, [properties]);

  const filteredProperties = useMemo(() => {
    if (activeTab === "all") return properties;
    return properties.filter((prop) => prop.tower === activeTab);
  }, [properties, activeTab]);

  useEffect(() => {
    if (initialLoad) {
      dispatch(fetchProperties());
    }
  }, [dispatch, initialLoad]);

  useEffect(() => {
    if (activeTab === 'all' && !initialLoad) {
      dispatch(fetchProperties(activeTab));
    }
  }, [activeTab, dispatch, initialLoad]);

  const handleTabChange = (tower) => {
    dispatch(setActiveTab(tower));
  };
  
  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="min-h-screen bg-backgroundGrey">
      {loading && <CircularLoader />}
      <Header />
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-6">
        <BuildingInfo />
        <TowerTabs
          activeTab={activeTab}
          setActiveTab={handleTabChange}
          towers={[...towers]}
        />
        <PropertyTable data={filteredProperties} />
      </div>
    </div>
  );
};

export default App;