import React from "react";
import logo from "../../assets/logo.png";
import building from "../../assets/building.png";

const Header = () => {
  return (
    <header className="bg-white shadow-sm">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-4">
        <div className="flex items-center justify-between">
          <div className="flex items-center">
            <img
              src={logo}
              alt="JOY Logo"
              className="h-8 w-8 sm:h-10 sm:w-10"
            />
            <h1 className="ml-3 sm:ml-4 text-lg sm:text-custom-22 font-lato font-bold text-gray-900 tracking-[0.1px]">
              Joy App
            </h1>
          </div>

          <div className="bg-white rounded-full flex items-center lg:hidden">
            <img src={building} alt="Building Icon" className="w-10 h-10" />
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
