import React from "react";
import phone from "../../../assets/phone.png";
import Badge from "../../common/Badge";

const TowerCard = ({
  tower,
  propertyId,
  noOfBed,
  baseRent,
  proRatedPrice,
  view,
  propertyType,
  bua,
  status,
  contact,
}) => {
  return (
    <div className="bg-white rounded-lg shadow-sm p-4 mb-4">
      <div className="space-y-3">
        {/* Tower and Availability */}
        <div className="flex justify-between items-center">
          <div>
            <h3 className="font-poppins font-semibold text-text-12 text-black">
              Tower
            </h3>
            <p className="text-text-16 font-poppins font-semibold text-black">
              {tower}
            </p>
          </div>
          <span className="text-text-14 font-lato font-bold">
            <Badge status={"Available"} />
          </span>
        </div>

        {/* <div className="border-b border-lineGrey mx-[-36px]" /> */}
        <div className="border-b border-lineGrey" />

        {/* Property Details */}
        <div className="space-y-2">
          <div className="flex justify-between">
            <span className="text-text-12 text-black font-poppins font-semibold">
              Property ID
            </span>
            <span className="text-text-12 text-black font-poppins font-normal">
              {propertyId}
            </span>
          </div>
          <div className="border-b border-lineGrey" />

          <div className="flex justify-between">
            <span className="text-text-12 text-black font-poppins font-semibold">
              No. of Beds
            </span>
            <span className="text-text-12 text-black font-poppins font-normal">
              {noOfBed}
            </span>
          </div>
          <div className="border-b border-lineGrey" />

          <div className="flex justify-between">
            <span className="text-text-12 text-black font-poppins font-semibold">
              Base Rent (QAR)
            </span>
            <span className="text-text-12 text-black font-poppins font-normal">
              {baseRent}
            </span>
          </div>
          <div className="border-b border-lineGrey" />

          <div className="flex justify-between">
            <span className="text-text-12 text-black font-poppins font-semibold">
            Pro Rated Price
            </span>
            <span className="text-text-12 text-black font-poppins font-normal">
              {proRatedPrice}
            </span>
          </div>
          <div className="border-b border-lineGrey" />

          <div className="flex justify-between">
            <span className="text-text-12 text-black font-poppins font-semibold">
              Location
            </span>
            <span className="text-text-12 text-black font-poppins font-normal">
              {view}
            </span>
          </div>
          <div className="border-b border-lineGrey w-full" />

          <div className="flex justify-between">
            <span className="text-text-12 text-black font-poppins font-semibold">
              Apartment Type
            </span>
            <span className="text-text-12 text-black font-poppins font-normal">
              {propertyType}
            </span>
          </div>
          <div className="border-b border-lineGrey" />

          <div className="flex justify-between">
            <span className="text-text-12 text-black font-poppins font-semibold">
              BUA (SQM)
            </span>
            <span className="text-text-12 text-black font-poppins font-normal">
              {bua}
            </span>
          </div>
        </div>

        {/* Contact Button */}
        <button className="w-full py-2 px-4 bg-white border border-textBlue rounded-lg text-text-14 text-textBlue flex items-center justify-center gap-x-3">
          <img src={phone} alt="Building Icon" className="w-5 h-5" />
          {contact}
        </button>
      </div>
    </div>
  );
};
export default TowerCard;
